import * as Sentry from '@sentry/react'

Sentry.init({
  dsn: 'https://26e0f4daaecd4bc3ab121800ac4b03c4@o112361.ingest.sentry.io/6541039',
  environment: process.env.AUTH_PF_ENV,
  enabled: Boolean(process.env.SENTRY_ENABLED),
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
