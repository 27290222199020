import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import { useClientSetting } from 'src/clientSetting/Provider'

export const useClientTags = () => {
  const client = useClientSetting()

  useEffect(() => {
    Sentry.setTag('clientId', client.id)
    return () => {
      Sentry.setTag('clientId', null)
    }
  }, [client.id])
}
