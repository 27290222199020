import type { ParsedUrlQuery } from 'querystring'
import {
  CookieLoginParams,
  CookieLoginParamsQuery,
  getParams as getCookieLoginParams,
  toCookieLoginParamsQuery,
  toSearchParams as cookieLoginParamsToSearchParams,
} from 'src/cookieLogin/params'
import {
  getParams as getOIDCLoginParams,
  OIDCLoginParams,
  OIDCLoginParamsQuery,
  toOIDCLoginParamsQuery,
  toSearchParams as oidcLoginParamsToSearchParams,
} from 'src/oidc/params'

export type ConnectParams = CookieLoginParams & OIDCLoginParams

export type ConnectParamsQuery = CookieLoginParamsQuery & OIDCLoginParamsQuery

export const toConnectParamsQuery = (params: ConnectParams): ConnectParamsQuery => {
  return { ...toCookieLoginParamsQuery(params), ...toOIDCLoginParamsQuery(params) }
}

export const getParams = (query: ParsedUrlQuery): ConnectParams => {
  return { ...getCookieLoginParams(query), ...getOIDCLoginParams(query) }
}

export const toSearchParams = (params: ConnectParams, base?: URLSearchParams) => {
  let s = new URLSearchParams(base)
  s = oidcLoginParamsToSearchParams(params, s)
  s = cookieLoginParamsToSearchParams(params, s)

  return s
}
