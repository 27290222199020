import React from 'react'
import { clientTheme, Theme } from './clientTheme'

type ThemeContextValue = {
  theme: Theme
}

export const ThemeContext = React.createContext<ThemeContextValue>({
  theme: clientTheme.default,
})
