import React, { useCallback } from 'react'
import { ConnectParams, toConnectParamsQuery } from './params'
import { ConnectParamsContext } from './paramsContext'

// 各種連携パラメータ（OIDC連携、セッション連携）を取得し、Contextに注入します
export const ConnectParamsProvider = ({
  connectParams,
  children,
}: {
  connectParams: ConnectParams
  children: React.ReactNode
}) => {
  const paramsReady = true
  const params = connectParams

  const getUrlQuery = useCallback(() => {
    return { query: toConnectParamsQuery(params) }
  }, [params])

  return (
    <ConnectParamsContext.Provider
      value={{
        paramsReady,
        params,
        getUrlQuery,
      }}
    >
      {children}
    </ConnectParamsContext.Provider>
  )
}
