import React from 'react'
import { ConnectParams, ConnectParamsQuery } from './params'

type ConnectParamsContextValue = {
  paramsReady: boolean
  params: ConnectParams
  getUrlQuery: () => { query: ConnectParamsQuery }
}

export const ConnectParamsContext = React.createContext<ConnectParamsContextValue>({
  paramsReady: false,
  params: { cookieLogin: null, oidc: null },
  getUrlQuery: () => ({ query: {} }),
})
