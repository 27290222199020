import React, { FC, ReactNode, useContext } from 'react'
import { useConnectParams } from 'src/connect/useParams'
import { ClientSettingContext } from './Context'
import { ClientSetting, clientSettings, getClientSettingByCookieLoginClientId } from './settings'

// 引数で与えられた固定のクライアント設定を提供します
export const StaticClientSettingProvider: FC<{ client: ClientSetting; children: ReactNode }> = ({
  client,
  children,
}) => {
  return <ClientSettingContext.Provider value={client}>{children}</ClientSettingContext.Provider>
}

// URLパラメータに応じてクライアント設定を提供します
export const ClientSettingFromConnectParamsProvider = ({ children }: { children: ReactNode }) => {
  const params = useConnectParams()
  if (!params.paramsReady) {
    return null // TODO:
  }

  const clientId = params.params.cookieLogin?.clientId
  const theme =
    (clientId && getClientSettingByCookieLoginClientId(clientId)) || clientSettings.default

  return <ClientSettingContext.Provider value={theme}>{children}</ClientSettingContext.Provider>
}

export const useClientSetting = () => useContext(ClientSettingContext)
