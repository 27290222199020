import type { AppProps } from 'next/app'
import Head from 'next/head'
import {
  ClientSettingFromConnectParamsProvider,
  useClientSetting,
} from 'src/clientSetting/Provider'
import { getHeadMetaTitle } from 'src/clientSetting/settings'
import { ConnectParams } from 'src/connect/params'
import { ConnectParamsProvider } from 'src/connect/paramsProvider'
import { ServerSidePropsWithConnectParams } from 'src/connect/serverSideProps'
import { ChakraWithClientThemeProvider } from 'src/theme/Provider'
import '../../styles/globals.css'
import { AppApolloProvider } from '../apollo/provider'
import '../sentry/init'
import { useClientTags } from '../sentry/useClientTags'

function MyApp({ Component, pageProps }: AppProps) {
  // NOTE: 各ページに配置される getServerSideProps によって取得されます。
  const connectParams: ConnectParams = (
    (pageProps || {}) as Partial<ServerSidePropsWithConnectParams>
  ).connectParams || {
    oidc: null,
    cookieLogin: null,
  }

  return (
    <ConnectParamsProvider connectParams={connectParams}>
      <ClientSettingFromConnectParamsProvider>
        <AppApolloProvider>
          <SentryTags />
          <ChakraWithClientThemeProvider>
            <AppHead />
            <Component {...pageProps} />
          </ChakraWithClientThemeProvider>
        </AppApolloProvider>
      </ClientSettingFromConnectParamsProvider>
    </ConnectParamsProvider>
  )
}

const AppHead = () => {
  const client = useClientSetting()
  return (
    <Head>
      <title>{getHeadMetaTitle(client.id)}</title>
    </Head>
  )
}

const SentryTags = () => {
  useClientTags()
  return null
}

export default MyApp
