import {
  ApolloClient,
  ApolloLink,
  defaultDataIdFromObject,
  InMemoryCache,
  StoreObject,
} from '@apollo/client'
import possibleTypesGen from '../types/possibleTypes.gen.json'
import { createUploadLink } from 'apollo-upload-client'
import { createMaintenanceCheckLink } from './createMaintenanceCheckLink'

const createCache = () => {
  const cache = new InMemoryCache({
    dataIdFromObject,
    possibleTypes: possibleTypesGen.possibleTypes,
  })

  return cache
}

export const dataIdFromObject = (object: Readonly<StoreObject>) => {
  if (object.__typename && object.uid) {
    return `${object.__typename}:${object.uid}`
  }
  return defaultDataIdFromObject(object)
}

export const createClient = (host: string) => {
  return new ApolloClient({
    cache: createCache(),
    link: ApolloLink.from([
      createMaintenanceCheckLink(),
      createUploadLink({
        uri: `${host}/id/doctors/graphql`,
        credentials: 'include',
      }),
    ]),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
  })
}
