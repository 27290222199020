import { ApolloProvider } from '@apollo/client'
import { FC, ReactNode, useMemo } from 'react'
import { createClient } from './client'
import { useHost } from './useHost'

export const AppApolloProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const host = useHost()
  const client = useMemo(() => createClient(host), [host])

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
