import { onError } from '@apollo/client/link/error'

const UNDER_MAINTENANCE_STATUS_CODE = 503

// メンテナンスモード有効時(ステータス503)、メンテナンス画面へリダイレクトする
export const createMaintenanceCheckLink = () => {
  return onError(({ networkError }) => {
    if (
      networkError &&
      'statusCode' in networkError &&
      networkError.statusCode === UNDER_MAINTENANCE_STATUS_CODE
    ) {
      // NOTE: 現状この画面上では MOMO のみなので固定値とする
      const clientId = 'momo'
      const host = process.env.AUTH_PF_CLINIC_APP_HOST

      // メンテナンスページへのリダイレクト URL を作成
      window.location.href = `${host}/maintenance?client_id=${clientId}`
    }
  })
}
