// クライアント（遷移元のプロダクト）の設定
// サーバ側で定義すべき値もありますが、現在はハードコードしています

export type ClientSetting = {
  id: ClientIds
  productName: string
}

export type ClientIds = 'default' | 'momo' | 'curon-clinic-web' | 'id-clinic'

export const clientSettings: Record<ClientIds, ClientSetting> = {
  default: {
    id: 'default',
    productName: '__NAME__',
  },
  momo: {
    id: 'momo',
    productName: 'MOMO',
  },
  'curon-clinic-web': {
    id: 'curon-clinic-web',
    productName: 'CuronClinicWeb',
  },
  'id-clinic': {
    id: 'id-clinic',
    productName: 'IdClinic',
  },
}

export const COOKIE_LOGIN_CLIENT_ID_MOMO = 'momo'
export const COOKIE_LOGIN_CLIENT_ID_CURON_CLINIC_WEB = 'curon-clinic-web'
export const COOKIE_LOGIN_CLIENT_ID_ID_CLINIC = 'id-clinic'

export const getClientSettingByCookieLoginClientId = (clientId: string) => {
  if (clientId === COOKIE_LOGIN_CLIENT_ID_MOMO) {
    return clientSettings.momo
  }
  if (clientId === COOKIE_LOGIN_CLIENT_ID_CURON_CLINIC_WEB) {
    return clientSettings['curon-clinic-web']
  }
  if (clientId === COOKIE_LOGIN_CLIENT_ID_ID_CLINIC) {
    return clientSettings['id-clinic']
  }

  return null
}

export const getHeadMetaTitle = (clientId: ClientIds) => {
  switch (clientId) {
    case 'momo':
      return 'クロンスマートパス'
    case 'curon-clinic-web':
      return 'クロンオンライン診療'
    case 'id-clinic':
    default:
      return 'curon（クロン）'
  }
}
