import type { ParsedUrlQuery } from 'querystring'

export type CookieLoginParams = {
  cookieLogin: {
    clientId: string
    redirectUri: string
  } | null
}

export type CookieLoginParamsQuery = {
  client_id?: string
  redirect_uri?: string
}

export const toCookieLoginParamsQuery = (params: CookieLoginParams): CookieLoginParamsQuery => {
  return params.cookieLogin
    ? {
        client_id: params.cookieLogin.clientId,
        redirect_uri: params.cookieLogin.redirectUri,
      }
    : {}
}

export const getParams = (query: ParsedUrlQuery): CookieLoginParams => {
  const { client_id: clientId, redirect_uri: redirectUri } = query as {
    client_id: string | undefined
    redirect_uri: string | undefined
  }

  return {
    cookieLogin:
      clientId && redirectUri
        ? {
            clientId,
            redirectUri,
          }
        : null,
  }
}

export const toSearchParams = (params: CookieLoginParams, base?: URLSearchParams) => {
  const s = new URLSearchParams(base)
  if (params.cookieLogin) {
    s.append('client_id', params.cookieLogin.clientId)
    s.append('redirect_uri', params.cookieLogin.redirectUri)
  }

  return s
}
